import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Resume = () => (
  <Layout>
    <Seo title="Resume" />
    <h1 style={{
      fontSize: `1.75rem`,
    }}>Resume</h1>

    <h3 id="software-engineering-manager-the-seattle-times">Software Engineering Manager, The Seattle Times (March 2021 - present)</h3>
    <ul>
      <li>Manages a team of eight software engineers responsible for seattletimes.com, iOS and Android mobile apps, and internal newsroom tools</li>
      <li>Reduced the number of distractions common to remote work, like Slack messages, to give team members more focused time by creating guidelines and expectations for how we communicate with each other</li>
      <li>Leads regularly scheduled developer-focused retrospectives so our team can collaboratively find solutions to improve code quality and tackle technical debt</li>
      <li>Works with designers to improve our Pattern Library, help streamline their interactions with engineers, and increase the amount of code engineers are able to reuse</li>
    </ul>
    <h3 id="lead-software-engineer-the-seattle-times">Lead Software Engineer, The Seattle Times (June 2017 - March 2021)</h3>
    <ul>
      <li>Received the <em>The Innovation Product & Development Team Values Award</em> for work and leadership on creating a new Article Page design</li>
      <li>Created an API using Node.js and GraphQL to provide information to a single-page React application for the newsroom to track the status of their articles from their inception through post-publication marketing efforts</li>
      <li>Gave talks to developers and other ST teams about various topics including Unit Testing, Continuous Integration/Continuous Delivery, GraphQL, Front-end Build Processes, and RabbitMQ</li>
      <li>Mentored junior developers through pair programming, more detailed asynchronous code reviews (using Pull Requests), open-ended phone & Slack conversations</li>
      <li>Created, implemented (by leading through example, presentations, individual meetings and code reviews) policies for writing unit tests for our code. These efforts took our team from one that didn't write tests to one that does.</li>
      <li>Researched, gained consensus for, and set up JS and PHP testing tools in our most critical code bases</li>
      <li>Helped our QA team troubleshoot and write automated tests using Robot Framework and Selenium</li>
      <li>Lead a ReactJS group open to all developers in the company</li>
    </ul>

    <h3 id="senior-software-engineer-the-seattle-times">Senior Software Engineer, The Seattle Times (December 2015-June 2017)</h3>
    <ul>
      <li>Refactored the code that controls curation on www.seattletimes.com and fixed bugs that resulted in dropping average server response time from over two seconds to around 300ms</li>
      <li>Rewrote code that allowed for www.seattletimes.com to run on 4 servers instead of 12-20 and remove multiple MySQL read replicas</li>
      <li>Identified backend (PHP and MySQL) and workflow performance bottlenecks</li>
      <li>Received IT Impact Award for making dramatic performance improvements on www.seattletimes.com</li>
    </ul>

    <h3 id="software-development-manager-the-seattle-times">Software Development Manager, The Seattle Times (December 2014-December 2015)</h3>
    <ul>
      <li>Gave presentations about web performance optimization to upper management</li>
      <li>Identified front-end performance issues that resulted in dropping Start Render times from 2.5-2.8 seconds to under 2 seconds</li>
      <li>Wrote white papers for upper management about front-end performance and reader-facing search</li>
      <li>Served as a ScrumMaster and helps the company transition to Agile software development practices</li>
      <li>Managed 4-5 developers</li>
      <li>Created bi-weekly “developer sessions” where developers across departments present and discuss development techniques</li>
      <li>Organized and participated in a reading group for developers and QA analysts about C</li>
      <li>Wrote documentation for developers to streamline our deployment and version control workflow</li>
      <li>Served on the Mobile Strategy Committee that helped shape how the Seattle Times understands mobile readers</li>
    </ul>

    <h3 id="software-engineer-the-seattle-times">Software Engineer, The Seattle Times (April 2014-December 2014)</h3>
    <ul>
      <li>Developed curation tools for redesigned seattletimes.com homepage and section indexes</li>
      <li>Collaborated with internal customers to conceptualize and refine seattletimes.com user experiences</li>
      <li>Coordinated with vendors to help augment our internal development team</li>
      <li>Helped update the workflow for Front-­end development using Grunt &amp; Sass</li>
      <li>Supported developers and users to better understand WordPress development and usage</li>
    </ul>

    <h3 id="interactive-developer-phinney-bischoff">Interactive Developer, Phinney Bischoff (2013-2014)</h3>
    <ul>
      <li>Rebuilt phinneybischoff.com to be responsive using Django, Backbone.js, and Bootstrap</li>
      <li>Developed a web application for managing and reusing documentation</li>
      <li>Created interactive wireframes and applied visual vocabulary (Style Tiles) to them</li>
      <li>Refactored PHP code on legacy sites to ease performance bottlenecks</li>
      <li>Participated in client pitches for web-based projects</li>
      <li>Helped write proposals and case studies for RFPs</li>
      <li>Provided support for WordPress and Drupal sites</li>
      <li>Created HTML emails and templates for third-party mail applications</li>
    </ul>

    <h3 id="web-applications-specialist-college-of-william-mary">Web Applications Specialist, College of William &amp; Mary (2009-2013)</h3>
    <ul>
      <li>Designed and developed a responsive dictionary Web application using Django and Backbone.js</li>
      <li>Migrated XML dictionary data into MySQL database using Python</li>
      <li>Developed a WordPress plugin and theme that integrated Google Maps and WordPress</li>
      <li>Developed a Kickstarter-like website for funding undergraduate research</li>
      <li>Developed MVC-based PHP applications with CodeIgniter</li>
      <li>Maintained and supported an 900 site/3800 user multi-site installation of WordPress</li>
      <li>Reduced the number of Web-related support requests by creating online documentation</li>
      <li>Led introductory Web workshops for faculty, staff, and students</li>
      <li>Provided individual support for Web services</li>
      <li>Wrote posts and served as an editor for Academic Information Services blogs</li>
      <li>Transitioned legacy Web applications from PHP4 to PHP5</li>
      <li>Set Web-related policy at W&amp;M as member of the Web Collaboration Team</li>
    </ul>

    <h3 id="academic-technologist-college-of-william-mary">Academic Technologist, College of William &amp; Mary (2008-2009)</h3>
    <ul>
      <li>Developed a WordPress theme to match the design of W&amp;M’s existing main website</li>
      <li>Customized WordPress themes and moved sites from custom CMS applications to WordPress</li>
      <li>Designed and developed a MVC-based application system for matching teachers looking for mentors working in commercial industries</li>
      <li>Awarded Information Technology “Game Ball” for excellent service</li>
      <li>Consulted with faculty about using the Web for teaching and research</li>
    </ul>

    <h3 id="teaching-fellow--adjunct-faculty-american-studies-program-college-of-william-mary">Teaching Fellow / Adjunct Faculty, American Studies Program, College of William &amp; Mary (2007-2008)</h3>
    <ul>
      <li>Taught Classes about American Popular Culture and the American War in Vietnam</li>
    </ul>

    <h3 id="education">Education</h3>
    <ul>
      <li>Doctor of Philosophy, American Studies (College of William &amp; Mary, 2013)</li>
      <li>Master of Arts, American Studies (College of William &amp; Mary, 2006)</li>
      <li>Bachelor of Arts, American Studies (College of William &amp; Mary, 2003)</li>
    </ul>
  </Layout>
)

export default Resume
